// no padding
.cb-no-padding {
    padding: 0 !important;
}

.cb-no-padding-top {
    padding-top: 0 !important;
}

.cb-no-padding-right {
    padding-right: 0 !important;
}

.cb-no-padding-bottom {
    padding-bottom: 0 !important;
}

.cb-no-padding-left {
    padding-left: 0 !important;
}

// 1x padding
.cb-padding {
    padding: 8px !important;
}

.cb-padding-top {
    padding-top: 8px !important;
}

.cb-padding-right {
    padding-right: 8px !important;
}

.cb-padding-bottom {
    padding-bottom: 8px !important;
}

.cb-padding-left {
    padding-left: 8px !important;
}

// 2x padding

.cb-padding-2x {
    padding: 16px !important;
}

.cb-padding-top-2x {
    padding-top: 16px !important;
}

.cb-padding-right-2x {
    padding-right: 16px !important;
}

.cb-padding-bottom-2x {
    padding-bottom: 16px !important;
}

.cb-padding-left-2x {
    padding-left: 16px !important;
}

// 2x padding

.cb-padding-3x {
    padding: 24px !important;
}

.cb-padding-top-3x {
    padding-top: 24px !important;
}

.cb-padding-right-3x {
    padding-right: 24px !important;
}

.cb-padding-bottom-3x {
    padding-bottom: 24px !important;
}

.cb-padding-left-3x {
    padding-left: 24px !important;
}

// no margin
.cb-no-margin {
    margin: 0 !important;
}

.cb-no-margin-top {
    margin-top: 0 !important;
}

.cb-no-margin-right {
    margin-right: 0 !important;
}

.cb-no-margin-bottom {
    margin-bottom: 0 !important;
}

.cb-no-margin-left {
    margin-left: 0 !important;
}

// 1x margin
.cb-margin {
    margin: 8px !important;
}

.cb-margin-top {
    margin-top: 8px !important;
}

.cb-margin-right {
    margin-right: 8px !important;
}

.cb-margin-bottom {
    margin-bottom: 8px !important;
}

.cb-margin-left {
    margin-left: 8px !important;
}

// 2x margin

.cb-margin-2x {
    margin: 16px !important;
}

.cb-margin-top-2x {
    margin-top: 16px !important;
}

.cb-margin-right-2x {
    margin-right: 16px !important;
}

.cb-margin-bottom-2x {
    margin-bottom: 16px !important;
}

.cb-margin-left-2x {
    margin-left: 16px !important;
}

// 2x margin

.cb-margin-3x {
    margin: 24px !important;
}

.cb-margin-top-3x {
    margin-top: 24px !important;
}

.cb-margin-right-3x {
    margin-right: 24px !important;
}

.cb-margin-bottom-3x {
    margin-bottom: 24px !important;
}

.cb-margin-left-3x {
    margin-left: 24px !important;
}

.cb-margin-left-4x {
    margin-left: 36px !important;
}

.cb-margin-nil {
    margin: 0 !important;
}

.cb-margin-bottom-nil {
    margin-bottom: 0 !important;
}

mat-radio-group > mat-radio-button {
    margin-bottom: 8px !important;
}

.block {
    display: block;
}

[hidden] {
    display: none !important;
}
