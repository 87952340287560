@import "./colors";

.standardTable {
    table {
        width: 100%;
        background-color: #fff;
        border-collapse: collapse;
        border: 1px solid #d0d0d0;
        border-top: none;
        thead {
            border-collapse: collapse;
            width: 100%;
            background-color: $cb-blue;
            color: #fff;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            td,
            th {
                padding: 5px;
                color: #fff;
                &.required:after {
                    content: "\00a0\2731";
                    color: #ffaaaa;
                }
                &.digit {
                    text-align: right;
                }
                &.right-align {
                    text-align: right !important;
                }
            }
            &.sticky-header {
                td,
                th {
                    position: sticky;
                    top: 0;
                    z-index: 2;
                    background-color: $cb-blue;
                    color: #fff;
                }
            }
        }
        tbody {
            td {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.3px;
                padding: 5px;
                div {
                    margin: 0;
                }
                &.digit {
                    text-align: right;
                }
                &.right-align {
                    text-align: right !important;
                }
            }
            tr,
            .table-row {
                min-height: 49px;
                height: 49px;
                &:nth-child(2n) {
                    background-color: #f0f0f0;
                }
                &:hover,
                &.highlight,
                &:nth-child(2n).highlight {
                    background-color: rgba(68, 133, 243, 0.4);
                }
                &.table-subhead {
                    border-bottom: 1px solid #999;
                    border-top: 1px solid black;
                    td {
                        padding: 30px 5px 10px 5px;
                    }
                }
                &.take-off-activity {
                    td {
                        font-size: 16px;
                        font-weight: 450;
                    }
                }
            }
        }
    }
    > table {
        .md-button.md-icon-button {
            height: 36px;
            width: 36px;
            padding: 6px;
        }
    }
    .checkbox-list md-input-container md-checkbox {
        margin-bottom: 0;
    }
    td.actions {
        white-space: nowrap;
        width: 1px;
    }
    td.cost {
        text-align: right;
    }
    td.highlight-cell {
        background-color: rgba($cb-accent, 0.2);
    }
}

.align-top {
    vertical-align: top;
}

.table-warn {
    box-sizing: border-box;
    border: 1px solid rgba(255, 87, 34, 1);
    background-color: rgba(255, 87, 34, 0.4) !important;
    &:hover {
        border-color: rgba(255, 87, 34, 0.8);
        background-color: rgba(255, 87, 34, 0.3) !important;
    }
}

// Note that the borders were not visible on the sides anyway,
// so remove them since they cause a scrollbar.
.table-warn-no-scrollbar {
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 87, 34, 1);
    border-bottom: 1px solid rgba(255, 87, 34, 1);
    background-color: rgba(255, 87, 34, 0.4) !important;
    &:hover {
        border-color: rgba(255, 87, 34, 0.8);
        background-color: rgba(255, 87, 34, 0.3) !important;
    }
}

.table-green {
    box-sizing: border-box;
    border: 1px solid rgba($cb-green, 1);
    background-color: rgba($cb-green, 0.4) !important;
    &:hover {
        border-color: rgba($cb-green, 0.8);
        background-color: rgba($cb-green, 0.3) !important;
    }
}

// Note that the borders were not visible on the sides anyway,
// so remove them since they cause a scrollbar.
.table-green-no-scrollbar {
    box-sizing: border-box;
    border-top: 1px solid rgba($cb-green, 1);
    border-bottom: 1px solid rgba($cb-green, 1);
    background-color: rgba($cb-green, 0.4) !important;
    &:hover {
        border-color: rgba($cb-green, 0.8);
        background-color: rgba($cb-green, 0.3) !important;
    }
}

.table-accent {
    box-sizing: border-box;
    border: 1px solid $cb-blue;
    background-color: rgba($cb-blue, 0.4) !important;
    &:hover {
        border-color: rgba($cb-blue, 0.8);
        background-color: rgba($cb-blue, 0.3) !important;
    }
}

.table-disabled {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.4);
    &:hover {
        background-color: rgba(0, 0, 0, 0.2) !important;
    }
}

.table-abandoned:not(.table-disabled) {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(255, 87, 34, 0.4) !important;
    &:hover {
        border-color: rgba(255, 87, 34, 0.8);
        background-color: rgba(255, 87, 34, 0.3) !important;
    }
}

.table-pink:not(.table-disabled) {
    box-sizing: border-box;
    border: 1px solid #f5aaff;
    background-color: rgba(#f5aaff, 0.4) !important;
    &:hover {
        border-color: rgba(#f5aaff, 0.8);
        background-color: rgba(#f5aaff, 0.3) !important;
    }
}

.table-olive:not(.table-disabled) {
    box-sizing: border-box;
    border: 1px solid #bab86c;
    background-color: rgba(#bab86c, 0.4) !important;
    &:hover {
        border-color: rgba(#bab86c, 0.8);
        background-color: rgba(#bab86c, 0.3) !important;
    }
}

.table-grey:not(.table-disabled) {
    box-sizing: border-box;
    border: 1px solid #777;
    background-color: rgba(#777, 0.4) !important;
    &:hover {
        border-color: rgba(#777, 0.8);
        background-color: rgba(#777, 0.3) !important;
    }
}

.fixed-header-table {
    table thead tr {
        display: block;
    }
    table th,
    table td {
        width: 600px;
    }
    table tbody {
        display: block;
        height: 500px;
        overflow: auto;
    }
}
