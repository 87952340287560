.cb-list {
    > li {
        list-style: none;
    }
    &.layout-row > li {
        &:before {
            content: ",";
        }
        &:first-of-type:before {
            display: none;
        }
    }
}
