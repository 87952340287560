// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.all-component-typographies();
@include mat.core();

$mat-classicgreen: (
    50: #e2f2e9,
    100: #b6dfc7,
    200: #85caa2,
    300: #54b47c,
    400: #30a460,
    500: #0b9444,
    600: #0a8c3e,
    700: #088135,
    800: #06772d,
    900: #03651f,
    A100: #95ffab,
    A200: #62ff83,
    A400: #2fff5b,
    A700: #15ff47,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$mat-classicaccent: (
    50: #e9f1ff,
    100: #c7dcff,
    200: #a2c5ff,
    300: #7cadff,
    400: #609cff,
    500: #448aff,
    600: #3e82ff,
    700: #3577ff,
    800: #2d6dff,
    900: #1f5aff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #cedaff,
    A700: #b5c6ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$mat-classicwarn: (
    50: #fff0e4,
    100: #ffdabd,
    200: #ffc291,
    300: #ffaa64,
    400: #ff9743,
    500: #ff8522,
    600: #ff7d1e,
    700: #ff7219,
    800: #ff6814,
    900: #ff550c,
    A100: #ffffff,
    A200: #fff8f6,
    A400: #ffd2c3,
    A700: #ffbfa9,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$classichub-primary: mat.define-palette($mat-classicgreen);
$classichub-accent: mat.define-palette($mat-classicaccent);
// The warn palette is optional (defaults to red).
$classichub-warn: mat.define-palette($mat-classicwarn);

$classichub-theme: mat.define-light-theme((
    color: (
        primary: $classichub-primary,
        accent: $classichub-accent,
        warn:  $classichub-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($classichub-theme);
/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
    font-size: 14px;
    margin: 0;
    background-color: #f9f9f9;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
}

@import "../node_modules/dragula/dist/dragula.css";
// from https://danielykpan.github.io/date-time-picker/
// @import "../node_modules/ng-pick-datetime/assets/style/picker.min.css";
@import "../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@import "./assets/scss/colors";
@import "./assets/scss/material-overrides";
@import "./assets/scss/icons";
@import "./assets/scss/layout";
@import "./assets/scss/tables";
@import "./assets/scss/dialogs";
@import "./assets/scss/cards";
@import "./assets/scss/forms";
@import "./assets/scss/cursor";
@import "./assets/scss/lists";
@import "./assets/scss/text";
@import "./assets/scss/others";
@import "./assets/scss/table";
@import "./assets/scss/flex";
