.filter-selects {
    min-width: 150px;
}

.simple-breadcrumbs {
    display: flex;
    align-items: flex-start;
    li {
        list-style: none;
        display: block;
        padding-left: 10px;
        &:first-of-type {
            padding-left: 0px;
        }
        &:after {
            padding-left: 10px;
            content: ">";
            align-self: flex-end;
        }
        &:last-of-type:after {
            display: none;
        }
    }
}

// for document upload
.dropzone {
    border: 5px dashed grey;
    background: #efefef;
    height: auto;
    min-height: 120px;
}

.errorContainer {
    background-color: #f9f9f9;
    border-radius: 3px;
    border: 1px solid #eee;

    .odd {
        background-color: #eeeeee;
    }

    span.rowCount {
        display: inline-block;
        width: 100px;
    }
}

#mainContainer {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;

    #ngView.active {
        height: 100%;
    }
}

// positioning a button with a blocking reason popup button
.blocking-reason-popup {
    position: absolute;
    right: -20px;
    top: 7px;
    z-index: 2;
}

.button-container {
    position: relative;
}

// for action-bar button
.flexible-gap {
    display: flex !important;
    flex-wrap: wrap;
    gap: 8px;
}

.full-width {
    width: 100%;
}

.mat-card-content-row {
    display: flex !important;
    flex-direction: row !important;
}

.card-divider {
    margin: 0 16px !important;
}

// The default bottom margin for mat-mdc-card-avatar is 16px, which is really big for our card header
.mat-mdc-header-card-avatar {
    margin-bottom: 0px !important;
}
