//region Layout Direction - Row

/*fxLayout="row"*/
.flex-row {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.flex-display {
    display: flex;
}

.flex-initial {
    flex: initial !important;
}
//endregion Layout Direction - Row


//region Layout Direction - Column

/*fxLayout="column"*/
.flex-col {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

//endregion Layout Direction - Column

//region Start all possible Vertical and Horizontal Alignment

.start-start {
    display: flex;
    place-content: flex-start;
    align-items: flex-start;
}

.start-center {
    display: flex;
    place-content: center flex-start;
    align-items: center;
}

.start-end {
    display: flex;
    place-content: flex-end flex-start;
    align-items: flex-end;
}

.start, .start-none, .start-stretch {
    display: flex;
    place-content: stretch flex-start;
    align-items: stretch;
}

.center-start {
    display: flex;
    place-content: flex-start center;
    align-items: flex-start;
}

.center-center {
    display: flex;
    place-content: center;
    align-items: center;
}

.center-end {
    display: flex;
    place-content: flex-end center;
    align-items: flex-end;
}

.center-right {
    justify-content: flex-end;
    align-items: center;
}

.center, .center-none, .center-stretch {
    display: flex;
    place-content: stretch center;
    align-items: stretch;
}

.end-start {
    display: flex;
    place-content: flex-start flex-end;
    align-items: flex-start;
}

.end-center {
    display: flex;
    place-content: center flex-end;
    align-items: center;
}

.end-end {
    display: flex;
    place-content: flex-end;
    align-items: flex-end;
}

.end, .end-none, .end-stretch {
    display: flex;
    place-content: stretch flex-end;
    align-items: stretch;
}

.space-around-start {
    display: flex;
    place-content: flex-start space-around;
    align-items: flex-start;
}

.space-around-center {
    display: flex;
    place-content: center space-around;
    align-items: center;
}

.space-around-end {
    display: flex;
    place-content: flex-end space-around;
    align-items: flex-end;
}

.space-around, .space-around-none, .space-around-stretch {
    display: flex;
    place-content: stretch space-around;
    align-items: stretch;
}

.space-between-start {
    display: flex;
    place-content: flex-start space-between;
    align-items: flex-start;
}

.space-between-center {
    display: flex;
    place-content: center space-between;
    align-items: center;
}

.space-between-end {
    display: flex;
    place-content: flex-end space-between;
    align-items: flex-end;
}

.space-between, .space-between-none, .space-between-stretch {
    display: flex;
    place-content: stretch space-between;
    align-items: stretch;
}

.space-evenly-start {
    display: flex;
    place-content: flex-start space-evenly;
    align-items: flex-start;
}

.space-evenly-center {
    display: flex;
    place-content: center space-evenly;
    align-items: center;
}

.space-evenly-end {
    display: flex;
    place-content: flex-end space-evenly;
    align-items: flex-end;
}

.space-evenly, .space-evenly-none, .space-evenly-stretch {
    display: flex;
    place-content: stretch space-evenly;
    align-items: stretch;
}
//endregion Start all possible Vertical and Horizontal Alignment

//region fxFlex

/*fxFlex*/
.flex {
    flex: 1;
    box-sizing: border-box;
}

.flex-wrap {
  display: flex;
  flex-flow: row wrap;
    box-sizing: border-box;
}

.flex-wrap-column {
    display: flex;
    flex-flow: column wrap;
}


.flex-fill {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
}


.flex-none {
    flex: 0 0 auto;
    box-sizing: border-box;
}

/*flex-x 1 - 100 */
@for $i from 1 through 100 {
    .flex-#{$i} {
        flex: 1 1 100%;
        max-width: #{$i}+'%';
        box-sizing: border-box;
    }
}

//fxLayoutGap="20px"
@for $i from 1 through 100 {
    .layoutGap-#{$i} > *:not(:last-child) {
        margin-right: #{$i} + 'px';
    }
}

@for $i from 1 through 100 {
    .layoutGap-col-#{$i} > *:not(:last-child) {
        margin-bottom: #{$i} + 'px';
    }
}

//endregion fxFlex


// region Media Query

//breakpoint	mediaQuery
//xs	        'screen and (max-width: 599px)'
//sm	        'screen and (min-width: 600px) and (max-width: 959px)'
//md	        'screen and (min-width: 960px) and (max-width: 1279px)'
//lg	        'screen and (min-width: 1280px) and (max-width: 1919px)'
//xl	        'screen and (min-width: 1920px) and (max-width: 5000px)'

@media (max-width: 599px) {
    .xs-column {
        display: flex;
        flex-direction: column;
    }
    .xs-row {
        display: flex;
        flex-direction: row;
    }
}

@media (min-width: 600px) and (max-width: 959px) {
    .sm-column {
        display: flex;
        flex-direction: column;
    }
    .sm-row {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 959px) {
    .xs-sm-column {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 1279px) {
    .xs-md-column {
        display: flex;
        flex-direction: column;
    }
}
@media (min-width: 960px) and (max-width: 1279px) {
    .md-column {
        display: flex;
        flex-direction: column;
    }
    .md-row {
        display: flex;
        flex-direction: row;
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .lg-column {
        display: flex;
        flex-direction: column;
    }
    .lg-row {
        display: flex;
        flex-direction: row;
    }
}

@media (min-width: 960px) {
    .gt-sm-column {
        display: flex;
        flex-direction: column;
    }
    .gt-sm-row {
        display: flex;
        flex-direction: row;
    }
    .gt-sm-start {
        display: flex;
        place-content: center flex-start;
        align-items: center;
    }
    .gt-sm-flex {
        flex: 1;
        box-sizing: border-box;
    }
    .gt-sm-start-center {
        display: flex;
        place-content: center flex-start;
        align-items: center;
    }
}

@media (min-width: 960px) {
    .gt-sm-hide {
        display: none;
    }
}

@media (min-width: 1920px) {
    .xl-column {
        display: flex;
        flex-direction: column;
    }
    .xl-row {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: 599px) {
    @for $i from 1 through 100 {
        .lt-sm-#{$i} {
            flex: 0 0 #{$i}+'%';
            max-width: #{$i}+'%';
            box-sizing: border-box;
        }
    }
}

@media screen and (max-width: 959px) {
    @for $i from 1 through 100 {
        .lt-md-#{$i} {
            flex: 0 0 #{$i}+'%';
            max-width: #{$i}+'%';
            box-sizing: border-box;
        }
    }
    .lt-md-column {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 1279px) {
    @for $i from 1 through 100 {
        .lt-lg-#{$i} {
            flex: 0 0 #{$i}+'%';
            max-width: #{$i}+'%';
            box-sizing: border-box;
        }
    }
}

@media screen and (max-width: 1919px) {
    @for $i from 1 through 100 {
        .lt-xl-#{$i} {
            flex: 0 0 #{$i}+'%';
            max-width: #{$i}+'%';
            box-sizing: border-box;
        }
        .lt-xl-hide {
            display: none;
        }
    }
}

@media screen and (min-width: 600px) {
    @for $i from 1 through 100 {
        .gt-xs-#{$i} {
            flex: 0 0 #{$i}+'%';
            max-width: #{$i}+'%';
            box-sizing: border-box;
        }
    }
}

@media screen and (min-width: 960px) {
    @for $i from 1 through 100 {
        .gt-sm-#{$i} {
            flex: 0 0 #{$i}+'%';
            max-width: #{$i}+'%';
            box-sizing: border-box;
        }
    }
}

@media screen and (min-width: 1280px) {
    @for $i from 1 through 100 {
        .gt-md-#{$i} {
            flex: 0 0 #{$i}+'%';
            max-width: #{$i}+'%';
            box-sizing: border-box;
        }
    }
}

@media screen and (min-width: 1920px) {
    @for $i from 1 through 100 {
        .gt-lg-#{$i} {
            flex: 0 0 #{$i}+'%';
            max-width: #{$i}+'%';
            box-sizing: border-box;
        }
    }
}


@media (max-width: 599px) {
  @for $i from 1 through 100 {
    .xs-#{$i} {
        flex: 0 0 #{$i}+'%';
        max-width: #{$i}+'%';
        box-sizing: border-box;
    }
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  @for $i from 1 through 100 {
    .sm-#{$i} {
        flex: 0 0 #{$i}+'%';
        max-width: #{$i}+'%';
        box-sizing: border-box;
    }
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  @for $i from 1 through 100 {
    .md-#{$i} {
        flex: 0 0 #{$i}+'%';
        max-width: #{$i}+'%';
        box-sizing: border-box;
    }
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  @for $i from 1 through 100 {
    .lg-#{$i} {
        flex: 0 0 #{$i}+'%';
        max-width: #{$i}+'%';
        box-sizing: border-box;
    }
  }
}

@media (min-width: 1920px) {
  @for $i from 1 through 100 {
    .xl-#{$i} {
        flex: 0 0 #{$i}+'%';
        max-width: #{$i}+'%';
        box-sizing: border-box;
    }
  }
}
//endregion Media Query

