

/*load material icons from npm package instead of cdn
 font loaded in angular.json from node_modules/material-design-icons/iconfont */
@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url("./MaterialIcons-Regular.woff2") format("woff2");
}
