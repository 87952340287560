.cb-table {
    overflow: visible;
    box-sizing: border-box;
    border: 1px solid #d0d0d0;
    padding-left: 0;
    .mat-mdc-table {
        min-width: 500px;
        width: calc(100% + 0.3px);
        box-sizing: border-box;
        margin-left: -0.3px;
    }

    .mat-column-actions {
        white-space: nowrap;
        width: 1px;
        padding-right: 8px;
        padding-left: 8px;
        > *:not(:last-child) {
            margin-right: 8px;
        }
    }

    .mat-column-position,
    .mat-column-position {
        padding-left: 8px;
    }

    .mat-mdc-cell.mat-mdc-table-sticky {
        &:first-child {
            border-right: 1px solid #e0e0e0;
        }
        &:last-child {
            border-left: 1px solid #e0e0e0;
        }
    }

    .mat-mdc-row {
        .mat-mdc-cell {
            vertical-align: middle;
        }
        &:nth-child(2n) {
            background-color: #f0f0f0;
        }
        &:hover,
        &.highlight,
        &:nth-child(2n).highlight {
            background-color: lighten($cb-blue, 30);
        }
    }

    .mat-mdc-header-row {
        background-color: $cb-blue;
        min-height: 36px;
        max-height: 36px;
        height: 36px;

        .mat-mdc-header-cell {
            color: white;
            font-size: 14px;
            min-height: 36px;
            max-height: 36px;
            height: 36px;

            vertical-align: middle;
        }
    }
}

.cb-table *:not(.cb-margin) > cb-info-message:not(.cb-margin),
.cb-table:not(.cb-margin) > cb-info-message,
.cb-table *:not(.cb-margin) > cb-loading-spinner,
.cb-table:not(.cb-margin) > cb-loading-spinner {
    height: 49px;
    align-items: center;
}

mat-table.mat-mdc-table:not(table) {
    display: table;
    mat-header-row,
    .mat-mdc-header-row,
    mat-row,
    .mat-mdc-row {
        display: table-row;
    }
    mat-header-cell,
    .mat-mdc-header-cell,
    mat-cell,
    .mat-mdc-cell {
        display: table-cell;
    }
}

.cb-table .mat-mdc-row .mat-mdc-cell,
.cb-table .mat-mdc-header-row .mat-mdc-header-cell {
    padding: 0 8px 0 8px;
    vertical-align: middle;
}

.mat-mdc-table .table-currency {
    text-align: right;
}
