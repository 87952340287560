$cb-green: #0b9444;
$cb-text: #484848;
$cb-lightText: #808080;
$cb-blue: rgb(46, 111, 224);
$cb-accent: rgb(68, 138, 255);
$cb-warn: rgb(255, 133, 34);
$light-gray: rgba(0, 0, 0, 0.12);
$cb-error: #dd0011;
$cb-dark-grey: #484848;
$cb-light-grey: #d0d0d0;
$cb-grey: #808080;

.cb-dark-grey {
    color: #484848 !important;
}
.cb-grey {
    color: #808080 !important;
}
.cb-light-grey {
    color: #d0d0d0 !important;
}
.cb-primary {
    color: $cb-green;
}
.cb-accent {
    color: $cb-accent;
}
.cb-warn {
    color: $cb-warn;
}
.cb-error {
    color: $cb-error;
}

.cb-primary-bg {
    background-color: $cb-green;
}
.cb-accent-bg {
    background-color: $cb-accent;
}
.cb-warn-bg {
    background-color: $cb-warn;
}
.cb-error-bg {
    background-color: $cb-error;
}

.cb-dark-gray-bg {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.mat-error {
    background-color: rgba(255, 87, 34, 0.1);
    border-color: rgba(255, 87, 34, 0.2) !important;
    color: $cb-error;
}

.mat-primary.cb-icon-color-danger {
    color: $cb-error;
}

.is-credit {
    color: red !important;
}
