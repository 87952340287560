.cb-dialog-container .mdc-dialog__container {
    height: auto;
    padding: 0;
    overflow: hidden;
    margin: 8px 0;
}

.cb-full-width-dialog .mdc-dialog__container {
    max-width: 100vw !important;
    margin: 0;
    height: auto;
    padding: 0;
    overflow: hidden;
}

.cb-lightbox-dialog-container {
    max-width: 100vw !important;
    min-width: 100vw !important;
    max-height: 100vh !important;
    min-height: 100vh !important;
    > .mdc-dialog__container {
        max-width: 100vw !important;
        min-width: 100vw !important;
        margin: 0;
        max-height: 100vh !important;
        min-height: 100vh !important;
        padding: 0;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.84) !important;
        display: block;
        position: relative;
        border-radius: 0px;
    }
}
cb-dialog footer {
    margin-top: 24px !important;
}

cb-dialog footer > *:not(:last-child) {
    margin-right: 8px !important;
}