.cb-card-avatar-icon {
    place-content: flex-start flex-end;
    align-items: flex-start;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    width: auto !important;
    height: auto;
}

mat-card-actions.with-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0 -16px;
}
