@import "./colors";

mat-form-field.cb-soft-required {
    label.mat-mdc-floating-label > mat-label::after {
        content: "*";
        display: inline-block;
        color: $cb-blue;
        margin-left: 2px;
    }
}

.cb-required::after {
    content: "*" !important;
    display: inline-block !important;
    color: $cb-error !important;
    margin-left: 2px !important;
}

.mat-headline-6.with-divider {
    margin-bottom: 2px !important;
}
