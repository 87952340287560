mat-icon .cb-grey {
    color: $cb-grey !important;
}

.cb-small-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
    justify-content: center;
    line-height: 16px;
    align-items: center;
    display: flex;
}
