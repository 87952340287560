.strike-text {
    text-decoration-line: line-through;
}

.capitalize {
    text-transform: uppercase;
}

.no-wrap {
    white-space: nowrap;
}

.force-wrap {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
}

.preserve-line-breaks {
    white-space: pre-line;
}

.restricted-width-text {
    display: block;
    line-break: auto;
    word-break: break-word;
    white-space: pre-line;
}

.cb-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.005em;
    height: auto;
    min-height: 24px;
    line-height: 24px;
    margin: 0 0 6px 0;
    overflow-wrap: break-word;
}

.cb-error-card {
    background-color: rgba($cb-error, 0.2);
    border: 1px solid $cb-error;
}

.cb-disabled-card {
    opacity: 0.6;
}

.cb-disabled {
    color: rgba(0, 0, 0, 0.38);
}
